// extracted by mini-css-extract-plugin
export var aboutIcon = "index-module--about-icon--yB98s";
export var aboutIconContainer = "index-module--about-icon-container--TiT-3";
export var aboutIconSection = "index-module--about-icon-section--WSrZ4";
export var aboutIconTitle = "index-module--about-icon-title--p0IJ1";
export var aboutLink = "index-module--about-link--Lx9uB";
export var aboutSection = "index-module--about-section--M5U4r";
export var aboutSectionDescription = "index-module--about-section-description--MrMX2";
export var aboutSectionHr = "index-module--about-section-hr--nK6lS";
export var aboutSectionSubtitle = "index-module--about-section-subtitle--WxCH+";
export var aboutSectionSubtitleAccent = "index-module--about-section-subtitle-accent--SrjZK";
export var adBanner = "index-module--ad-banner--80v0w";
export var articleCardsContainer = "index-module--article-cards-container--mU7Dl";
export var articleSection = "index-module--article-section--xrg+B";
export var articleSectionHeader = "index-module--article-section-header--VCpKV";
export var articleSectionSubtitle = "index-module--article-section-subtitle--J8ge0";
export var articleSectionSubtitleAccent = "index-module--article-section-subtitle-accent---7Z5E";
export var articleSectionTitle = "index-module--article-section-title--p6fg5";
export var indexHeroSubtitle = "index-module--index-hero-subtitle--2Jpit";
export var recommendSection = "index-module--recommend-section--+Vv7T";
export var recommendSectionRecommendArticles = "index-module--recommend-section-recommend-articles--aqn+R";
export var recommendSectionSubtitle = "index-module--recommend-section-subtitle--ANcoT";
export var recommendSectionSubtitleAccent = "index-module--recommend-section-subtitle-accent--RNeaO";
export var viewAllLink = "index-module--view-all-link--vl5JA";
export var viewAllLinkContainer = "index-module--view-all-link-container--V3aG5";