import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ArticleCard from "../components/article-card";
import * as styles from "./index.module.scss";
import CodingIcon from "../images/svg/coding_icon.svg";
import BusinessIcon from "../images/svg/business_icon.svg";
import MarketingIcon from "../images/svg/marketing_icon.svg";

export default function Index({ data, location }) {
  var articles = data.wagtailbase.pages;

  // sort by article date
  articles.sort(function (a, b) {
    if (a.date < b.date) return 1;
    if (a.date > b.date) return -1;
    return 0;
  });

  articles = articles.slice(0, 18);
  var r1 = data.wagtailbase.r1;
  var r2 = data.wagtailbase.r2;
  var r3 = data.wagtailbase.r3;
  var r4 = data.wagtailbase.r4;
  var r5 = data.wagtailbase.r5;
  var r6 = data.wagtailbase.r6;

  return (
    <Layout location={location}>
      <SEO />
      <main>
        <header className={styles.recommendSection}>
          <p className={styles.recommendSectionSubtitleAccent}>Recommend</p>
          <h2 className={styles.recommendSectionSubtitle}>おすすめ記事</h2>
          <section className={styles.recommendSectionRecommendArticles}>
            <ArticleCard article={r1} key={r1.slug} />
            <ArticleCard article={r2} key={r2.slug} />
            <div className={styles.adBanner}>
              <a href="https://px.a8.net/svt/ejp?a8mat=3TCWN1+D7PD4I+4HUE+61RI9" rel="nofollow">
                <img style={{ border: "0px", width: "336px", height: "280px"}} alt="" src="https://www27.a8.net/svt/bgt?aid=230709565799&wid=001&eno=01&mid=s00000020975001016000&mc=1" /></a>
              <img style={{ border: "0px", width: "1px", height: "1px" }} src="https://www15.a8.net/0.gif?a8mat=3TCWN1+D7PD4I+4HUE+61RI9" alt="" />
            </div>
            <ArticleCard article={r3} key={r3.slug} />
            <ArticleCard article={r4} key={r4.slug} />
            <ArticleCard article={r5} key={r5.slug} />
          </section>
        </header>
        <section className={styles.aboutSection}>
          <p className={styles.aboutSectionSubtitleAccent}>About</p>
          <h2 className={styles.aboutSectionSubtitle}>
            プログラマのキャリアハックメディア
          </h2>
          <hr className={styles.aboutSectionHr} />
          <p className={styles.aboutSectionDescription}>
            WagtailBaseは、個人のスキルとキャリアの向上に役立つ情報を発信するメディアです。
            <br />
            プログラミング・副業・転職・マーケティングについて役立つ知識を届けます。
          </p>
          <section className={styles.aboutIconSection}>
            <section className={styles.aboutIconContainer}>
              <CodingIcon className={styles.aboutIcon} />
              <p className={styles.aboutIconTitle}>プログラミング</p>
            </section>
            <section className={styles.aboutIconContainer}>
              <BusinessIcon className={styles.aboutIcon} />
              <p className={styles.aboutIconTitle}>仕事とキャリア</p>
            </section>
            <section className={styles.aboutIconContainer}>
              <MarketingIcon className={styles.aboutIcon} />
              <p className={styles.aboutIconTitle}>マーケティング</p>
            </section>
          </section>
        </section>
        <section className={styles.articleSection}>
          <div className={styles.articleSectionHeader}>
            <p className={styles.articleSectionSubtitleAccent}>New</p>
            <h2 className={styles.articleSectionSubtitle}>新着記事</h2>
          </div>
          <section className={styles.articleCardsContainer}>
            {articles.map((article) => {
              return <ArticleCard article={article} key={article.slug} />;
            })}
          </section>
          <section className={styles.viewAllLinkContainer}>
            <Link to={`/article/`} className={styles.viewAllLink}>
              すべての記事をみる
            </Link>
          </section>
        </section>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query {
    wagtailbase {
      pages(contentType: "articles.ArticleDetailPage") {
        ... on WagtailBase_ArticleDetailPage {
          slug
          title
          overview
          date
          bannerImage {
            url
            title
            thumbnailWebp: rendition(fill: "800x420", format: "webp") {
              url
            }
            thumbnailJpeg: rendition(fill: "800x420", format: "jpeg") {
              url
            }
          }
        }
      }
      r1: page(slug: "books-for-python-beginners") {
        ... on WagtailBase_ArticleDetailPage {
          slug
          title
          overview
          date
          bannerImage {
            url
            title
            thumbnailWebp: rendition(fill: "800x420", format: "webp") {
              url
            }
            thumbnailJpeg: rendition(fill: "800x420", format: "jpeg") {
              url
            }
          }
        }
      }
      r2: page(slug: "python-check-if-variable-is-none") {
        ... on WagtailBase_ArticleDetailPage {
          slug
          title
          overview
          date
          bannerImage {
            url
            title
            thumbnailWebp: rendition(fill: "800x420", format: "webp") {
              url
            }
            thumbnailJpeg: rendition(fill: "800x420", format: "jpeg") {
              url
            }
          }
        }
      }
      r3: page(slug: "python-list-comprehension") {
        ... on WagtailBase_ArticleDetailPage {
          slug
          title
          overview
          date
          bannerImage {
            url
            title
            thumbnailWebp: rendition(fill: "800x420", format: "webp") {
              url
            }
            thumbnailJpeg: rendition(fill: "800x420", format: "jpeg") {
              url
            }
          }
        }
      }
      r4: page(slug: "python-str-extract-patterns") {
        ... on WagtailBase_ArticleDetailPage {
          slug
          title
          overview
          date
          bannerImage {
            url
            title
            thumbnailWebp: rendition(fill: "800x420", format: "webp") {
              url
            }
            thumbnailJpeg: rendition(fill: "800x420", format: "jpeg") {
              url
            }
          }
        }
      }
      r5: page(slug: "python-f-strings") {
        ... on WagtailBase_ArticleDetailPage {
          slug
          title
          overview
          date
          bannerImage {
            url
            title
            thumbnailWebp: rendition(fill: "800x420", format: "webp") {
              url
            }
            thumbnailJpeg: rendition(fill: "800x420", format: "jpeg") {
              url
            }
          }
        }
      }
      r6: page(slug: "python-how-to-write-comments") {
        ... on WagtailBase_ArticleDetailPage {
          slug
          title
          overview
          date
          bannerImage {
            url
            title
            thumbnailWebp: rendition(fill: "800x420", format: "webp") {
              url
            }
            thumbnailJpeg: rendition(fill: "800x420", format: "jpeg") {
              url
            }
          }
        }
      }
    }
  }
`;
